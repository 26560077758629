div.registrationHeaderWrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 145px;
  height: auto;
  position: relative;
  top: 40px;
}

div.registrationHeaderWrapperError {
  width: 560px;
}

img.showboatLogo {
  display: inline-block;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

@media only screen and (max-width: 729.95px) {
  div.registrationHeaderWrapper {
    top: 20px;
    min-height: 100px;
    margin-bottom: -20px;
  }

  div.registrationHeaderWrapperError {
    width: 435px;
  }

}

@media only screen and (max-width: 529.95px) {
  div.registrationHeaderWrapper {
    
  }
}

@media only screen and (max-width: 419.95px) {
  
}
