body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  width: 100%;
  height: 100%;
}

div#root {
  height: 100%;
  width: 100%;
}

div {
  scrollbar-color: #5a5f69;
  scrollbar-width: thin;
}

div::-webkit-scrollbar {
  width: 0.4em;
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  position: relative;
  right: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div::-webkit-scrollbar-thumb {
  background-color: #5a5f69;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  border-radius: 15px;
}

/*Clear default 'x' from chrome and IE */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/**/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
